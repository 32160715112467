@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: 'Inter', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	--main-bg-color: #008445;
	--cancel-btn-colr: #CE4F53;
	--border-color: #C4C4C4;

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.mask-focus {
	outline: none;
}

/* // #006B68
// #008f50
// #FFC62F */
.head {
	background: #006B68;
	padding: 10px 0px;
}

.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center !important;
	padding: 10px 15px;
	border-bottom: 1px solid #eee;
	background: #006B68;
	color: white !important;
}

.left-icon {
	font-size: 25px !important;
	color: white !important;
}

.mask-focus:focus {
	border: 1.2px solid rgb(71, 136, 219) !important;
}


.image {
	-webkit-animation: spin 0.8s linear;
	-moz-animation: spin 0.8s linear;
	animation: spin 0.8s linear;
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotateX(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotateX(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
	}
}